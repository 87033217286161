import './secret.scss';

const Secret = () => {
    return (
        <div className="secret">
            <div className="secret__text" title="Danke Papa">
                Danke Papa
            </div>
        </div>
    );
};

Secret.displayName = 'Secret';

export default Secret;
