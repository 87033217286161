import { AppDispatch, GetAppState } from '../store';

export const loadGym =
    () =>
    async (dispatch: AppDispatch, getState: GetAppState): Promise<void> => {
        return;
    };

export const loadGyms =
    () =>
    async (dispatch: AppDispatch, getState: GetAppState): Promise<void> => {
        return;
    };
